/*
 *
 *   Bootstrap
 *   Table Options
*/
.table {
    font-size: em(13);

    th {
        font-weight: 600;
    }
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    border-top: 1px solid #d7e1ed;
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 1px solid #d7e1ed;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #EEF5F9;
}

.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table-hover > tbody > tr:hover {
    background-color: #EEF5F9;
}

.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
    border: 1px solid #d7e1ed;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}
