.mCSB_scrollTools{
      opacity: .4;
}
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox~.mCSB_scrollTools,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover~.mCSB_scrollTools,
.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox~.mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: .6;
}
