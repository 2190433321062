// Bootstrap Material Design variables start with mdb-
@import 'var-bootstrap';
// Typography elements
$mdb-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$mdb-text-color-light:  unquote("rgba(#{$rgb-white}, 0.84)") !default;
$mdb-text-color-light-hex: $white !default; // for contrast function in inverse
$mdb-text-color-primary: unquote("rgba(#{$rgb-black}, 0.87)") !default;
$mdb-text-color-primary-hex: $black !default; // for contrast function in inverse
$icon-color: rgba(0,0,0,0.5) !default;

$mdb-label-color: unquote("rgba(#{$rgb-black}, 0.26)") !default;
$mdb-label-color-toggle-focus: unquote("rgba(#{$rgb-black}, .54)") !default;

$mdb-brand-inverse: $md-indigo-500 !default;


/* ANIMATION */
$mdb-animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$mdb-animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$mdb-animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$mdb-animation-curve-default: $mdb-animation-curve-fast-out-slow-in !default;


// --------------------
// inputs
$mdb-input-placeholder-color: #758396 !default;
$mdb-input-underline-color: rgba(165, 181, 203,0.5) !default;
$mdb-label-static-size-ratio: 75 / 100 !default;
$mdb-help-block-size-ratio: 75 / 100 !default;

$mdb-input-font-size-base: 14px !default;
$mdb-input-font-size-large: ceil(($font-size-base * 1.25)) !default; // ~20px
$mdb-input-font-size-small: ceil(($font-size-base * 0.75)) !default; // ~12px

// FIXME: with #733 customization of bootstrap, consider how these could be based on the original bs customized variables
//** Unit-less `line-height` for use in components like buttons.
$mdb-input-line-height-base:        $line-height-base; //1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$mdb-input-line-height-computed:    floor(($mdb-input-font-size-base * $mdb-input-line-height-base)) !default; // ~20px
$mdb-input-line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$mdb-input-line-height-small:         1.5 !default;

//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$mdb-input-padding-base-vertical:     8px - 1px !default; // was 6.
$mdb-input-padding-base-horizontal:   0 !default; // was 12.
$mdb-label-as-placeholder-shim-base:  0 !default; // manual adjustment of label top when positioned as placeholder
$mdb-label-top-margin-base:           16px !default;

$mdb-input-padding-large-vertical:    10px - 1px !default; // 10
$mdb-input-padding-large-horizontal:  0 !default;  // 16
$mdb-label-as-placeholder-shim-large: -4px !default; // manual adjustment of label top when positioned as placeholder
$mdb-label-top-margin-large:          16px !default;

$mdb-input-padding-small-vertical:    4px - 1px !default; // 5
$mdb-input-padding-small-horizontal:  0 !default; // 10
$mdb-label-as-placeholder-shim-small: 8px !default; // manual adjustment of label top when positioned as placeholder
$mdb-label-top-margin-small:          12px !default;

$mdb-input-padding-xs-vertical:       2px !default; // 1
$mdb-input-padding-xs-horizontal:     0 !default; // 5

$mdb-input-border-radius-base:        3px !default;
$mdb-input-border-radius-large:       6px !default;
$mdb-input-border-radius-small:       3px !default;


//** Default `.form-control` height
$mdb-input-height-base:              ($mdb-input-line-height-computed + ($mdb-input-padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$mdb-input-height-large:             (ceil($mdb-input-font-size-large * $mdb-input-line-height-large) + ($mdb-input-padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$mdb-input-height-small:             (floor($mdb-input-font-size-small * $mdb-input-line-height-small) + ($mdb-input-padding-small-vertical * 2) + 2) !default;




// Card
$mdb-card-body-text: $mdb-text-color-primary !default;
$mdb-card-body-background: #fff !default;
$mdb-card-image-headline: #fff !default;


// Checkboxes
$mdb-checkbox-size: 20px !default;
$mdb-checkbox-animation-ripple: 500ms !default;
$mdb-checkbox-animation-check: 0.3s !default;
$mdb-checkbox-checked-color: $brand-info !default;

$mdb-checkbox-border-size: 2px !default;
$mdb-checkbox-label-color: $mdb-label-color !default;
$mdb-checkbox-border-color: $mdb-label-color-toggle-focus !default;

// Popovers and Popups
$mdb-popover-background: rgba(101, 101, 101, 0.9) !default;
$mdb-popover-color: #ececec !default;

// Dropdown Menu
$mdb-dropdown-font-size: 16px !default;

// Toggle
$mdb-toggle-label-color: $mdb-label-color !default;

// Radio - Default:
$mdb-radio-label-color: $mdb-label-color !default;
$mdb-radio-color-off: $mdb-label-color-toggle-focus !default;
$mdb-radio-color-on: $brand-info !default;


// Buttons:
$mdb-btn-font-size-base: 14px !default;
$mdb-btn-font-size-lg: 16px !default;
$mdb-btn-font-size-sm: 12px !default;
$mdb-btn-font-size-xs: 10px !default;


$mdb-btn-background-color: $body-bg; //transparent !default;
$mdb-btn-background-color-text: $mdb-text-color-primary !default;


$mdl-btn-border-radus: 2px !default;
//$mdb-btn-primary-color: unquote("rgba(#{$rgb-grey-500}, 0.20)") !default;

$mdb-btn-fab-size: 56px !default;
$mdb-btn-fab-size-sm: 40px !default;
$mdb-btn-fab-size-xs: 30px !default;
$mdb-btn-fab-font-size: 24px !default;

$mdb-btn-icon-size: 32px !default;
$mdb-btn-icon-size-sm: 24px !default;
$mdb-btn-icon-size-xs: 18px !default;
/* SHADOWS */
$mdb-shadow-key-umbra-opacity: 0.2 !default;
$mdb-shadow-key-penumbra-opacity: 0.14 !default;
$mdb-shadow-ambient-shadow-opacity: 0.12 !default;
