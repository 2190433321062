//Full Page Chat

#chat-wrapper {
  .chat-row {
    padding: 12px 0;
    margin: 65px 0;
    
    .bubble {
      max-width: 70%;
      display: inline;
      background: $mw-green;
      float: right;
      color: #fff;
      border-radius: 5px;
      padding: 10px;
      margin: 0 15px 0 0;
      font-size: 14px;
      position: relative;
      top: -5px;
      
      .message {
        p {
          padding: 0;
          margin: 0;
        }
      }
      
      .date {}
    }
    
    .bubble:after,
    .bubble:before {
      left: 99%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    .bubble:after {
      border-color: rgba(40, 190, 189, 0);
      border-left-color: #28bebd;
      border-width: 7px;
      margin-top: -7px;
    }
    
    .bubble:before {
      border-color: rgba(40, 190, 189, 0);
      border-left-color: #28bebd;
      border-width: 10px;
      margin-top: -10px;
    }
    
    &.first {
      margin-top: 0;
    }
    
    &.last {
      margin-bottom: 0;
    }
  }
  
  .chat-row.response {
    .bubble_response {
      max-width: 70%;
      display: inline;
      background: darken(#E3ECF7,3%);
      float: left;
      color: #607188;
      border-radius: 5px;
      padding: 10px;
      margin: 0 0 0 15px;
      font-size: 14px;
      position: relative;
      top: -5px;
      
      .message {
        p {
          padding: 0;
          margin: 0;
        }
      }
      
      .date {}
    }
    
    .bubble_response:after,
    .bubble_response:before {
      right: 99%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    .bubble_response:after {
      border-color: rgba(215, 228, 244, 0);
      border-right-color: #D7E4F4;
      border-width: 7px;
      margin-top: -7px;
    }
    
    .bubble_response:before {
      border-color: rgba(215, 228, 244, 0);
      border-right-color: #D7E4F4;
      border-width: 10px;
      margin-top: -10px;
    }
  }
}

.chat-bubble {
  height: 36px;
  width: 75px;
  border-radius: 60px;
  background: #d7e4f4;
  position: relative;
  margin: -10px 0 0 60px;
}

.chat-bubble:before {
  content: '';
  height: 23px;
  width: 23px;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  margin-left: -7px;
  top: 11px;
}
// .chat-bubble:after {
//   content: '';
//   height: 36px;
//   width: 36px;
//   right: -36px;
//   position: absolute;
//   display: block;
//   background: #d7e4f4;
//   border-radius: 50%;
//   z-index: 1;
// }

.chat-bubble .tail {
  height: 25px;
  width: 25px;
  background: #d7e4f4;
  position: absolute;
  left: -40px;
  bottom: 2px;
  border-radius: 50%;
}

.chat-bubble .tail:before {
  height: 12px;
  width: 12px;
  background: #d7e4f4;
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  bottom: -8px;
}

.chat-bubble .loading {
  position: absolute;
  width: 67px;
  left: 14px;
  top: 13px;
}

.chat-bubble .loading .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: darken(#E3ECF7,2%);
  display: block;
  float: left;
  margin: 0 0 0 8px;
}

.chat-bubble .loading .dot:first-child {
  margin: 0;
}

.chat-bubble .loading .dot.one {
  -webkit-animation: cycleOne 1s ease-in-out infinite;
  -webkit-animation-direction: normal;
}

.chat-bubble .loading .dot.two {
  -webkit-animation: cycleTwo 1s ease-in-out infinite;
  -webkit-animation-direction: normal;
}

.chat-bubble .loading .dot.three {
  -webkit-animation: cycleThree 1s ease-in-out infinite;
  -webkit-animation-direction: normal;
}
@-webkit-keyframes cycleOne {
  0% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  33.333% {
    background: lighten(#E3ECF7,1%);
  }
  
  66.6667% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  100% {
    background: rgba(184, 201, 221, 0.4);
  }
}
@-webkit-keyframes cycleTwo {
  0% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  33.333% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  66.6667% {
    background: lighten(#E3ECF7,1%);
  }
  
  100% {
    background: rgba(184, 201, 221, 0.4);
  }
}
@-webkit-keyframes cycleThree {
  0% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  33.333% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  66.6667% {
    background: rgba(184, 201, 221, 0.4);
  }
  
  100% {
    background: lighten(#E3ECF7,1%);
  }
}
//Sidebar Chat

#chat_compose_wrapper {
  width: 320px;
  position: fixed;
  right: -420px;
  top: 65px;
  bottom: 0;
  z-index: 85;
  background: #EEF5F9;
  border-top: solid thin #e6edf1;
  @include transition(all 0.2s);
  
  .tippy-top {
    height: 60px;
    background: #fff;
    
    .recipient {
      color: #8F9EB5;
      padding: 20px;
    }
    
    .card-actions.icons > li.open > a > i,
    .card-actions.icons.open > a > i {
      color: #8F9EB5;
    }
    
    ul.card-actions.icons.left-top {
      left: 10px;
      top: 16px;
    }
    
    ul.card-actions.icons.right-top {
      right: 10px;
      top: 16px;
    }
  }
  
  .compose-form .form-group .form-control {
    padding: 22px 0 25px 20px;
  }
  
  .compose-form .form-group textarea {
    background-image: none;
  }
  
  &.open {
    top: 65px;
    bottom: 0;
    right: 280px;
  }
  
  #chat-wrapper {
    padding: 20px;
  }
  
  #compose-footer {
    position: absolute;
    z-index: 2;
    bottom: 0;
    background: #fff;
    
    ul.card-actions.icons.left-bottom {
      left: 12px;
      top: 19px;
      position: absolute;
      z-index: 2;
    }
    
    .form-group {
      padding-top: 12px;
    }
  }
}

.app-chat {
  @media (max-width: 992px) {
    .content-body {
      padding-left: 60px !important;
    }
  }
}
@media (max-width: $screen-sm) {
  .app-chat #content_type.boxed-leftnav,
  .card-overlay #content_type .content-body .card:first-child {
    box-shadow: none;
  }
  
  .app-chat #content_outer_wrapper #content_wrapper #content .content-body {
    padding: 0 0 20px 20px;
  }
}

#sidebar_chat {
  .list-group {
    .list-group-item {
      padding: 13px 20px;
      position: relative;
      
      .badge {
        position: absolute;
        top: 12px;
        left: 49px;
        border: 1px solid #fff;
      }
    }
  }
}
@media (max-width: $screen-md) {
  #chat_compose_wrapper {
    &.open {
      top: 65px;
      bottom: 0;
      right: 0;
      z-index: 100;
    }
  }
}

.chat-wrapper {
  width: 100%;
  display: table;
  overflow: hidden;
  padding: 20px;
}

.chat-wrapper:before {
  content: " ";
  display: block;
}

.chat-wrapper .chat-content {
  background-color: #fff;
}

.chat-wrapper .chat-image {
  float: left;
  border-radius: 50%;
}

.chat-wrapper .chat-image.chat-image-default {
  width: 45px;
  height: 45px;
}

.chat-wrapper .chat-message {
  margin-bottom: 20px;
  width: 100%;
  display: table;
  overflow: hidden;
}

.chat-wrapper .chat-message:before {
  content: " ";
  display: block;
}

.chat-wrapper .chat-message:last-of-type {
  margin-bottom: 0;
}

.chat-wrapper .chat-message .chat-message-wrapper {
  max-width: 70%;
  display: table;
  margin: 0 15px;
  padding-top: 10px;
  position: relative;
  
  .today {
    font-size: 12px;
  }
}

.chat-wrapper .chat-message .chat-message-wrapper:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  top: 20px;
  position: absolute;
  z-index: 2;
}

.chat-wrapper .chat-message .chat-message-wrapper p {
  padding: 5px 12px;
  color: #fff;
  border-top: 1px solid;
}

.chat-wrapper .chat-message .chat-message-wrapper p:first-of-type {
  border-top: 0 !important;
}

.chat-wrapper .chat-message .chat-message-wrapper p {
  font-weight: 200;
  line-height: 1.5em;
  font-size: 13px;
}

.chat-wrapper .chat-message.chat-message-recipient .chat-message-content,
.chat-wrapper .chat-message.chat-message-recipient .chat-message-wrapper {
  float: left;
}

.chat-wrapper .chat-message.chat-message-recipient .chat-message-content {
  background-color: #8f9eb5;
  color: #fff;
}

.chat-wrapper .chat-message.chat-message-recipient .chat-message-wrapper:before {
  left: -20px;
  border-right-color: #8f9eb5;
}

.chat-wrapper .chat-message.chat-message-recipient p {
  border: none;
}

.chat-wrapper .chat-message.chat-message-sender .chat-message-content,
.chat-wrapper .chat-message.chat-message-sender .chat-message-wrapper {
  float: right;
}

.chat-wrapper .chat-message.chat-message-sender .chat-message-content {
  background-color: #28bebd;
}

.chat-wrapper .chat-message.chat-message-sender .chat-message-wrapper:before {
  right: -20px;
  border-left-color: #28BEBD;
}

.chat-wrapper .chat-message.chat-message-sender p {
  border: none;
}

.chat-wrapper .chat-message.chat-message-sender img {
  float: right;
}

.chat-wrapper .chat-message .chat-message-content {
  display: table;
  overflow: hidden;
  border-radius: 4px;
}

.chat-wrapper .chat-message .chat-message-content:before {
  content: " ";
  display: block;
}

.chat-wrapper .chat-message .chat-details {
  clear: both;
  width: 100%;
  display: table;
  overflow: hidden;
}

.chat-wrapper .chat-message .chat-details:before {
  content: " ";
  display: block;
}

.chat-wrapper .chat-message .chat-details span {
  margin-right: 4px;
  color: #bbb;
}
@media (max-width: $screen-sm) {
  #chat_compose_wrapper {
    .chat-wrapper {
      width: 100%;
      display: block;
      overflow: hidden;
      padding: 20px;
      height: 100%;
      margin-bottom: 100px;
    }
    
    .chat-wrapper .chat-message:last-of-type {
      margin-bottom: 100px;
    }
  }
}
@media (max-width : 480px) {
  .app-chat{
    #content_type.boxed-leftnav .content-body .card .card-body{
      padding: 0 10px;
    }
  }
  #chat-wrapper {
    bottom: 140px!important;
    padding-top: 140px;
    
    .chat-row {
      img.img-circle {
        display: none;
      }
      
      .bubble,
      .bubble_response {
        margin: 15px auto;
            max-width: 88%;
      }
      
      .chat-bubble {
        margin: -10px 0 0 8px;
      }
      
      &.response {
        margin: 83px 0 0px!important;
      }
    }
  }
}
