//--------------------------------------------
// Auth Pages
// Login & Register UI v1
//--------------------------------------------
body#auth_wrapper {
	background-color: #607188;
  #login_wrapper {
    position: relative;
    margin: 5% auto auto;
    width: 375px;
    .logo {
      width: 115px;
      height: 115px;
      border-radius: 100%;
      box-shadow: 0 8px 12px 1px rgba(253, 232, 179,.14), 0 3px 14px 2px rgba(0,0,0,.12), 0 5px 5px -3px rgba(253, 232, 179,.2);
      z-index: 10;
      position: absolute;
      top: -50px;
      left: 34%;
      background: #fff;
      img {
        padding: 21px 24px;
      }
    }
    #login_content {
      position: relative;
      overflow: hidden;
      background: #fff;
      -webkit-box-shadow: 0 5px 12px rgba(0,0,0,.2);
      -moz-box-shadow: 0 5px 12px rgba(0,0,0,.2);
      -ms-box-shadow: 0 5px 12px rgba(0,0,0,.2);
      -o-box-shadow: 0 5px 12px rgba(0,0,0,.2);
      box-shadow: 0 5px 12px rgba(0,0,0,.2);
      position: relative;
      width: 375px;
      padding: 110px 30px 30px;
      h1 {
        text-align: center;
        font-size: 20px;
        margin: 10px auto 50px;
      }
    }
    #register_wrapper {
      padding: 110px 20px 20px 20px;
      margin-bottom: 0;
      position: absolute;
      background-color: #fff;
      width: 100%;
      background: #fff;
      overflow: hidden;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 3;
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      -webkit-transition: all,.2s,ease-in-out;
      -khtml-transition: all,.2s,ease-in-out;
      -moz-transition: all,.2s,ease-in-out;
      -ms-transition: all,.2s,ease-in-out;
      -o-transition: all,.2s,ease-in-out;
      transition: all,.2s,ease-in-out;
      box-shadow: none;
    }
    #login_content.open #register_wrapper {
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  // locked screen icon
  .icon-locked{
    font-size: 30px;
color: #f78d1f;
position: absolute;

margin-left: -30px;
margin-top: -14px;
  }
  
  
}
@media (max-width: 768px) {
  body#auth_wrapper1 {
    #login_wrapper {
      position: relative;
          margin: 25% auto auto;
          width: 100%;
      .logo {
      
        top: -60px;
      
      }
      #login_content {
       width: 100%;
        padding: 110px 30px 30px;
        h1 {
          text-align: center;
          font-size: 20px;
          margin: 10px auto 50px;
        }
      }
      #register_wrapper {
        padding: 110px 20px 20px 20px;
        margin-bottom: 0;
        position: absolute;
        background-color: #fff;
        width: 100%;
        background: #fff;
        overflow: hidden;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        z-index: 3;
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-transition: all,.2s,ease-in-out;
        -khtml-transition: all,.2s,ease-in-out;
        -moz-transition: all,.2s,ease-in-out;
        -ms-transition: all,.2s,ease-in-out;
        -o-transition: all,.2s,ease-in-out;
        transition: all,.2s,ease-in-out;
        box-shadow: none;
      }
      #login_content.open #register_wrapper {
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }
  }
  
}

//Shared login styles
#auth_wrapper

{
  .login-options {
    text-align: center;
    margin: 25px auto 0;
    min-height: 15px;
    position: relative;
    span {
      position: absolute;
      top: -9px;
      background: #fff;
      padding: 0 15px;
      margin: auto;
      left: 42%;
    }
  }
  .forgot-pass {
    font-size: 14px;
    padding-top: 7px;
  }
  a:link:not(.btn),
  a:visited:not(.btn) {
    color: $mw_blue;
  }
  a:active:not(.btn),
  a:hover:not(.btn) {
    color: darken($mw_blue,12%);
  }
}
