#footer_wrapper {
  margin: 0;
	position: absolute;
bottom: -65px;
  .footer-content {
    text-align: left;
    padding: 40px 30px 20px;
    
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      
      li {
        line-height: 2em;
        font-size: em(13);
        
        a,
        a:link,
        a:visited {
          color: rgba(255,255,255,.5);
          font-weight: 400;
        }
        
        a:active,
        a:hover {
          color: #fff;
        }
      }
    }
    
    h6,
    p,
    span {
      color: #fff;
    }
    
    h6 {
      font-size: em(18);
      margin: 0 0 15px;
    }
    
    p {
      font-size: em(13);
    }
    
    span {
      font-size: em(12);
    }
    
    .copy-wrapper {
      padding: 10px 0 0;
      border-top: solid thin rgba(255,255,255,.2);
      margin: 30px 0 0;
      
      .copy {
        color: rgba(255,255,255,.6);
        font-weight: 200;
      }
    }
    
    .form-group {
      .control-label {
        color: rgba(255,255,255,.6);
      }
      
      .form-control {
        background-image: none;
        margin: -5px 0 0;
        padding: 10px 20px;
        border-radius: 3px;
        height: 45px;
        display: inline-block;
        color: #fff;
        -webkit-transition: all 150ms ease-in-out;
        -khtml-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -ms-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
        background-color: rgba(255,255,255,.2);
      }
      
      .input-group-btn {
        top: -4px;
      }
      
      .form-control:focus {
        background-color: #fff;
        color: #4b5a6d;
        opacity: 1;
      }
      
      .input-group-addon {
        position: absolute;
        z-index: 15;
        left: 0;
        top: -1px;
        font-size: 25px;
        color: rgba(255,255,255,.6);
      }
      
      &.is-focused {
        .input-group-addon {
          i {
            color: #4b5a6d!important;
          }
        }
      }
      
      ::-webkit-input-placeholder {
        color: rgba(255, 255, 255,.6)!important;
      }
      
      :-moz-placeholder {
        color: rgba(255, 255, 255,.6)!important;
      }
      
      ::-moz-placeholder {
        color: rgba(255, 255, 255,.6)!important;
      }
      
      :-ms-input-placeholder {
        color: rgba(255, 255, 255,.6)!important;
      }
      
      input:focus::-webkit-input-placeholder {
        color: #4b5a6d!important;
      }
      
      input:focus:-moz-placeholder {
        color: #4b5a6d!important;
      }
      
      input:focus::-moz-placeholder {
        color: #4b5a6d!important;
      }
      
      input:focus:-ms-input-placeholder {
        color: #4b5a6d!important;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  #footer_wrapper{
    .footer-content{
      h6{
        margin: 15px 0;
      }
      padding: 20px
    }
  }
  
}
