//Bootstrap Alerts

.alert {
    @include border-radius(3px);

    b,
    optgroup,
    strong {
        font-weight: 600;
    }
}

.alert .alert-link,
.close {
    font-weight: 400;
    outline: none!important;
}

.alert-success {
    background-color: $brand-success;
    border: none;
    color: #fff;
}

.alert-success hr {
    border-top-color: darken($brand-success,5%);
}

.alert-success .alert-link {
    color: rgba(255,255,255,.9);
    text-decoration: underline;
}

.alert-info {
    background-color: $brand-info;
    border: none;
    color: #fff;
}

.alert-info hr {
    border-top-color: darken($brand-info,5%);
}

.alert-info .alert-link {
      color: rgba(255,255,255,.9);
    text-decoration: underline;
}

.alert-warning {
    background-color: $brand-warning;
    border: none;
    color: #fff;

    .alert .alert-link,
    .close {
        color: #fff;
    }
}

.alert-warning hr {
    border-top-color:#fff;
}

.alert-warning .alert-link {
    color: rgba(255,255,255,.9);
    text-decoration: underline;
}

.alert-danger {
    background-color: $brand-danger;
    border: none;
    color: #fff;
}

.alert-danger hr {
    border-top-color: darken($brand-danger,5%);
}

.alert-danger .alert-link {
    color: rgba(255,255,255,.9);
    text-decoration: underline;
}
//SweetAlerts Plugin

.swal2-modal {
    .swal2-title {
        font-family: $font-family-header;
        font-size: 1.25em;
        margin: 0;
        font-weight: 400;
        color: #516073;
    }

    .swal2-content {
        color: #607188;
            font-size: em(15);
        font-family: $font-family-body;
        font-weight: normal;
    }

    .swal2-styled {
        border: none;
        border-radius: $border-radius-base;
        position: relative;
        padding: 12px 30px;
        margin: 10px 1px;
        font-size: $mdb-btn-font-size-base;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0;
        will-change: box-shadow, transform;
        transition: box-shadow 0.2s $mdb-animation-curve-fast-out-linear-in, background-color 0.2s $mdb-animation-curve-default;
        outline: none;

        &::-moz-focus-inner {
            border: 0;
        }
    }

    .swal2-cancel,
    .swal2-close {
        color: $brand-default!important;
        background: transparent!important;
        box-shadow: none;

        &:hover {
            outline: 0;
            outline: none;
            box-shadow: none;
            background-color: rgba($brand-default,.2)!important;
        }
    }
}
//AlertifyJS Plugin
$padding-base: 12px;
$border-radius: 3px;

.alertify-logs {
    > * {
        padding: $padding-base $padding-base * 2;
        color: #fff;
        border-radius: $border-radius;

        &,
        &.default {
            background: $brand-default;
        }

        &.error {
            background: $brand-danger;
        }

        &.success {
            background: $brand-success;
        }
    }
}

.alertify {
    position: fixed;
    background: rgba(115,130,150,.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;

    &.hide {
        opacity: 0;
        pointer-events: none;
    }

    &,
    &.show {
        box-sizing: border-box;
        transition: all 0.33s cubic-bezier(.25, .8, .25, 1);
    }

    &,
    * {
        box-sizing: border-box;
    }

    .dialog {
        padding: 12px;
    }

    .alert,
    .dialog {
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: 30%;
        animation: showSweetAlert 0.3s;

        > * {
            width: 480px;
            margin: 0 auto;
            text-align: center;
            padding: 12px;
            border-radius: 4px;
            background: #EEF5F9;
            -webkit-box-shadow: 0 40px 77px rgba(0,0,0,.22),0 27px 24px rgba(0,0,0,.2);
            -moz-box-shadow: 0 40px 77px rgba(0,0,0,.22),0 27px 24px rgba(0,0,0,.2);
            box-shadow: 0 40px 77px rgba(0,0,0,.22),0 27px 24px rgba(0,0,0,.2);
        }

        .msg {
            margin: 0;
            text-align: left;
            min-height: 16px;
            padding: 10px;
            border-bottom: none;
            border-radius: 4px 4px 0 0;
            font-weight: 400;
            font-size: 17px;
            color: #3E4C5E;
            font-family: Poppins,sans-serif;
        }

        input:not(.form-control) {
            margin-bottom: 15px;
            width: 100%;
            font-size: 100%;
            padding: $padding-base;

            &:focus {
                outline-offset: -2px;
            }
        }

        nav {
            text-align: right;

            button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
                background: transparent;
                box-sizing: border-box;
                color: rgba(0,0,0,0.87);
                position: relative;
                outline: 0;
                border: 0;
                display: inline-block;
                align-items: center;
                padding: 0 6px;
                margin: 6px 8px;
                line-height: 36px;
                min-height: 36px;
                white-space: nowrap;
                min-width: 88px;
                text-align: center;
                text-transform: uppercase;
                font-size: 14px;
                text-decoration: none;
                cursor: pointer;
                border: 1px solid transparent;
                border-radius: 2px;

                &:active,
                &:hover {
                    background-color: rgba(0,0,0,.05);
                }

                &:focus {
                    border: 1px solid rgba(0, 0, 0, .1);
                }
            }

            button.btn {
                margin: 6px 4px;
            }
        }
    }
}

.alertify .alert,
.alertify .dialog {
    transform: none;
}

.alertify-logs {
    position: fixed;
    z-index: 99999;

    &.bottom,
    &:not(.top) {
        bottom: 16px;
        // Bottom left placement. Default. Use for transitions.
        &.left,
        &:not(.right) {
            > * {}
        }
        // Top right placement
        &.right {
            > * {}
        }
    }
    // All left positions.
    &.left,
    &:not(.right) {
        left: 16px;

        > * {
            float: left;
            //transform: translate3d(0, 0, 0);
            height: auto;

            &.show {
                left: 0;
            }

            &,
            &.hide {
                left: -110%;
            }
        }
    }

    &.right {
        right: 16px;

        > * {
            float: right;
            //transform: translate3d(0, 0, 0);
            &.show {
                right: 0;
                opacity: 1;
            }

            &,
            &.hide {
                right: -110%;
                opacity: 0;
            }
        }
    }

    &.top {
        top: 0;
        // Top left placement, use for transitions.
        &.left,
        &:not(.right) {
            > * {}
        }
        // Top right placement, use for transitions
        &.right {
            > * {}
        }
    }

    > * {
        box-sizing: border-box;
        transition: all 0.4s cubic-bezier(.25, .8, .25, 1);
        position: relative;
        clear: both;
        backface-visibility: hidden;
        perspective: 1000;

        & {
            max-height: 0;
            margin: 0;
            padding: 0;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;
        }

        &.show {
            margin-top: $padding-base;
            opacity: 1;
            max-height: 1000px;
            padding: $padding-base;
            pointer-events: auto;
        }
    }
}

.alertify .alert nav button.cancel:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button),
.alertify .dialog nav button.cancel:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
    @include btn-styles($brand-default);
}


.alertify-logs {
    position: absolute!important;
    z-index: 1000;
}

.alertify .alert input:not(.form-control),
.alertify .dialog input:not(.form-control) {
    outline: none!important;
}
@keyframes showSweetAlert {
    0% {
        transform: scale(.7);
    }

    45% {
        transform: scale(1.05);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes hideSweetAlert {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(.5);
    }
}
@keyframes slideFromTop {
    0% {
        top: 0;
    }

    100% {
        top: 50%;
    }
}
@keyframes slideToTop {
    0% {
        top: 50%;
    }

    100% {
        top: 0;
    }
}
@keyframes slideFromBottom {
    0% {
        top: 70%;
    }

    100% {
        top: 50%;
    }
}
@keyframes slideToBottom {
    0% {
        top: 50%;
    }

    100% {
        top: 70%;
    }
}

.showSweetAlert {
    animation: showSweetAlert 0.3s;
}

.showSweetAlert[data-animation=none] {
    animation: none;
}

.showSweetAlert[data-animation=slide-from-top] {
    animation: slideFromTop 0.3s;
}

.showSweetAlert[data-animation=slide-from-bottom] {
    animation: slideFromBottom 0.3s;
}

.hideSweetAlert {
    animation: hideSweetAlert 0.3s;
}

.hideSweetAlert[data-animation=none] {
    animation: none;
}

.hideSweetAlert[data-animation=slide-from-top] {
    animation: slideToTop 0.3s;
}

.hideSweetAlert[data-animation=slide-from-bottom] {
    animation: slideToBottom 0.3s;
}
