.breadcrumb {
    padding: 6px 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    font-size: em(12);

    li a {
        color: rgba(255,255,255,.5);
        font-weight: 400;
    }

    li a:hover,
    li.active {
        color: rgba(255,255,255,1);
    }
}

.breadcrumb > li+li:before {
    padding: 0 5px;
    color: rgba(255,255,255,.5);
    content: "\f2fb";
    font-family: Material-Design-Iconic-Font;
    font-size: 14px;
    vertical-align: bottom;
}

.mega.breadcrumb {
    padding: 5px 0 0!important;
    display: block!important;

    li,
    li a {
        font-size: 25px;
        font-weight: 200;
    }

    > li+li:before {
        padding: 12px 8px;
        color: rgba(255,255,255,.5);
        content: "\f2fb";
        font-family: Material-Design-Iconic-Font;
        font-size: 20px;
        vertical-align: initial;
    }
}

@media only screen and (max-width:768px) {
  #header .breadcrumb {
      display: none!important;
  }

}
