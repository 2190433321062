.popover{
  max-width: 300px;
  box-shadow: 0 5px 10px rgba(175,188,206,.6);
  border: 1px solid rgba(175,188,206,.3);
  font-family: $font-family-body;
  .popover-title{
    background-color: #EEF5F9;
        border-bottom: none;
        font-weight: 400;
        font-size: 16px;
        padding: 15px 10px;
    font-family: $font-family-body;
  }
  .popover-content{
    padding:9px 14px;
    font-family: $font-family-body;
  }
  .btn {
    font-family: $font-family-body;
  }
}
