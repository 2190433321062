@import '../../themes/theme-h';

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

.select2-container {
  margin: 0;
  position: relative;
  display: block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle;
}
.select2-container.form-control {
  padding: 0;
  height: auto;
}
.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
	  Force border-box so that % widths fit the parent
	  container without overlap because of margin/padding.
	  More Info : http://www.quirksmode.org/css/box.html
	*/
  -webkit-box-sizing: border-box;
  /* webkit */
  -moz-box-sizing: border-box;
  /* firefox */
  box-sizing: border-box;
  /* css3 */
}
.select2-container .select2-choice {

  height: 36px;

  position: relative;

  white-space: nowrap;


  text-decoration: none;


  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  /* @extend input */
  overflow: visible;

  font: inherit;

  touch-action: manipulation;

  margin: 0;
  line-height: inherit;
  border-radius: 0;

  box-sizing: inherit;

  /* @extend .form-control */
  display: block;

  width: 100%;
  color: #55595c;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);

  padding: .5rem 0 .6rem;
  font-size: 14px;
  line-height: 1.5;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  margin-top: .2rem;
  margin-bottom: 1rem;

  /* @extend input[type=text] */
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  outline: 0;
  //height: 2.1rem;
  width: 100%;
  font-size: 14px;
  box-shadow: none;
  transition: all .3s;

  min-height: 2.1rem;
}
html[dir="rtl"] .select2-container .select2-choice {
  padding: 0 8px 0 0;
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: rgba(12, 12, 12, 0.12);
  border-radius: 0 0 2px 2px;
}
.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}
.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: none;
  width: auto;
}
html[dir="rtl"] .select2-container .select2-choice > .select2-chosen {
  margin-left: 26px;
  margin-right: 0;
}
.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 36px;
  position: absolute;
  right: 24px;
  top: 0;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  outline: 0;
  background: transparent;
}
.select2-container .select2-choice abbr:before {
  display: none;
  font-family: Material-Design-Iconic-Font;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  color: rgba(165, 181, 203, 0.5);
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  margin: 1px;
  margin-right: 10px;
  content: "\f2f2";
  text-align: center;
}
.select2-container .select2-choice abbr:hover {
  text-decoration: none;
}
.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}
.select2-container.select2-allowclear .select2-choice abbr:before {
  display: inline-block;
}
.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}
.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-drop {
  width: 100%;
  margin-top: 0;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  border: 1px solid #aaa;
  border-top: 0;
  border-radius: 0 0 2px 2px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.select2-drop-active {
  border: 1px solid rgba(12, 12, 12, 0.12);
  border-top: none;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid rgba(12, 12, 12, 0.12);
}
.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}
.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}
.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
  background-clip: padding-box;
}
html[dir="rtl"] .select2-container .select2-choice .select2-arrow {
  left: 0;
  right: auto;
  border-left: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
}
.select2-container .select2-choice .select2-arrow b:before {
  display: block;
  font-family: Material-Design-Iconic-Font;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  color: #6f829c;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  margin-top: 1px;
  margin-right: 10px;
  content: "\f2f2";
  text-align: center;

}
html[dir="rtl"] .select2-container .select2-choice .select2-arrow b {
  background-position: 2px 1px;
}
.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 36px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}
.select2-search input {
  width: 100%;
  height: 2.1rem !important;
  min-height: 2.1rem;
  margin: 0;
  padding: 4px 20px 4px 5px;
  outline: 0;
  font-family: "Open Sans";
  font-size: 14px;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  margin-bottom: 16px;
  border: 0;
  border-bottom: 1px solid #ccc;
  transition: all .3s;

  &:focus {
    border-bottom: 1px solid #42a5f5;
    box-shadow: 0 1px 0 0 #42a5f5;
  }
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  outline: none;
}
.select2-dropdown-open .select2-choice {
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
}
.select2-dropdown-open.form-control {
  border-bottom-color: $app_primary;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid rgba(12, 12, 12, 0.12);
  border-top-color: transparent;
  border-bottom-color: transparent;
  background: #fff;
}
.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none;
}
html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow {
  border-right: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}
html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -16px 1px;
}
.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/* results */
.select2-results {

  padding: 0;

  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /* @extend .zf-shadow-depth* */
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);

  /* @extend .dropdown-content */
  background-color: #fff;
  margin: 0;
  min-width: 100px;
  max-height: 200px;
  z-index: 999;
  will-change: width,height;

}
html[dir="rtl"] .select2-results {
  padding: 0 4px 0 0;
  margin: 4px 0 4px 4px;
}
.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}
.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}
.select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results-dept-1 .select2-result-label {
  padding-left: 20px;
}
.select2-results-dept-2 .select2-result-label {
  padding-left: 40px;
}
.select2-results-dept-3 .select2-result-label {
  padding-left: 60px;
}
.select2-results-dept-4 .select2-result-label {
  padding-left: 80px;
}
.select2-results-dept-5 .select2-result-label {
  padding-left: 100px;
}
.select2-results-dept-6 .select2-result-label {
  padding-left: 110px;
}
.select2-results-dept-7 .select2-result-label {
  padding-left: 120px;
}
.select2-results .select2-highlighted {
  background: $app_accent;
  color: #fff;
}
.select2-results li em {
  background: #feffde;
  font-style: normal;
}
.select2-results .select2-highlighted em {
  background: transparent;
}
.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
}
/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}
.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}
.select2-results .select2-selected {
  display: none;
}
.select2-more-results.select2-active {
  background: #f4f4f4 url('select2-spinner.gif') no-repeat 100%;
}
.select2-results .select2-ajax-error {
  background: rgba(255, 50, 50, 0.2);
}
.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}
/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}
.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}
.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}
/* multiselect */
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0 5px 0 0;
  position: relative;
  border: 1px solid transparent;
  cursor: text;
  overflow: hidden;
}
html[dir="rtl"] .select2-container-multi .select2-choices {
  padding: 0 0 0 5px;
}
.select2-locked {
  padding: 3px 5px 3px 5px !important;
}
.select2-container-multi .select2-choices {
  min-height: 36px;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid transparent;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}
html[dir="rtl"] .select2-container-multi .select2-choices li {
  float: right;
}
.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.select2-container-multi .select2-choices .select2-search-field input {
  padding: 0;
  margin: 0;
  min-height: 34px;
  font-family: "Open Sans";
  font-size: 100%;
  color: #666;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
  outline: 0;
}
.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #ffffff url('select2-spinner.gif') no-repeat 100% !important;
}
.select2-default {
  color: #999 !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
  padding: 6px 5px 6px 18px;
  margin: 4px 4px 3px 0;
  position: relative;
  font-size: 13px;
  line-height: 13px;
  background-color: $app_primary;
  color: #ffffff;
  cursor: default;
  border: 1px solid $app_primary;
  border-radius: 0;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 18px 3px 5px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}
.select2-search-choice-close {
  display: block;
  width: 16px;
  height: 25px;
  position: absolute;
  right: 3px;
  top: 0;
  outline: none;
}
.select2-search-choice-close:before {
  display: block;
  font-family: Material-Design-Iconic-Font;
  font-weight: normal;
  font-style: normal;
  font-size: 10px;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  margin: 5px 0 0 0;
  content: "\f136";
  text-align: center;
  color: #fff;
}
.select2-search-choice-close:hover {
  background: transparent;
  text-decoration: none;
}
html[dir="rtl"] .select2-search-choice-close {
  right: auto;
  left: 3px;
}
.select2-container-multi .select2-search-choice-close {
  left: 0;
}
html[dir="rtl"] .select2-container-multi .select2-search-choice-close {
  left: auto;
  right: 2px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}
/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none;
}
/* end multiselect */
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}
.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}
.select2-display-none {
  display: none;
}
.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: url('select2x2.png') !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-search input {
    background-position: 100% -21px !important;
  }
}