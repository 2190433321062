@mixin mdb-label-color-toggle-focus() {
  .form-group.is-focused & {
    color: $mdb-label-color;
    
    &:focus,
    &:hover {
      color: $mdb-label-color-toggle-focus;
    }
    
    fieldset[disabled] & {
      color: $mdb-label-color;
    }
  }
}

.form-horizontal {
  .checkbox,
  .checkbox-inline,
  .radio,
  .radio-inline {
    padding-top: 7px;
  }
  
  .radio {
    margin-bottom: 10px;
  }
  
  label {
    text-align: right;
  }
  
  label.control-label {
    margin: 0;
  }
}

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.form-group {
  padding-bottom: 7px;
  position: relative;
}

.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: initial;
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 0 !important;
}

.form-group.is-focused .radio label,
.form-group.is-focused label.radio-inline {
  color: #758396!important;
}

.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.form-group.label-floating.is-empty {
  .input-group .input-group-addon + .control-label {
    left: 35px;
  }
}

.form-group.label-floating.is-empty.is-focused {
  .input-group .input-group-addon + .control-label {
    left: 0;
  }
}

.input-group-addon {
  font-size: 16px;
  font-weight: 200;
  color: inherit;
}

.input-group .input-group-btn.suffix-select {
  padding:0px!important;
  width: 15%;
}


//Input Rounded
.form-group{
  .form-control.form-rounded.input-white{
    background: #fff;
    border-radius: 30px;
    height: 40px;
    padding: 5px 25px;
    @include shadow-2dp-color($mw-grayBlue);
    
    
  }
}
.form-group{
  .form-control.form-rounded.input-lightGray{
    background:#EEF5F9;
    border-radius: 30px;
    height: 40px;
    padding: 5px 25px;
    @include shadow-2dp-color($mw-lightGray);
    
    
  }
}

.dropzone {
  min-height: 150px;
  background: #fff;
  border: none;
}

.dropzone .dz-default.dz-message {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  background-image: url("../img/fileupload/fileupload-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  height: 160px;
  padding-top: 140px;
  color: #c3d7e2;
}



.icon-upload > input
{
  display: none;
}
.icon-upload label{
  font-size: inherit;
  line-height: inherit;
  color: #688696;
  font-weight: normal;
  text-align: center;
  font-size: 1.125em!important;
}
.icon-upload i
{
  width: 30px;
  cursor: pointer;
}
 
