//grid demo
.show-grid{
  div[class^="col-"]{
    margin-bottom: 15px;
    padding: 15px;
    border: thin solid darken(#E3ECF7,3%);
    background: #EEF5F9;
    font-size:13px;
  }
}

//icon demo
.icon a {
  display: block;
  padding: 8px;
  line-height: 32px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: 2px;
}
.icon a:hover {
  color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.12),0 1px 2px 0 rgba(0,0,0,.24);
}


//animation demo
.animation-demo{
  text-align: center;
  img{
    width:200px;
  }
  .btn{
    text-transform: none!important;
  }
}

//Material Color Demo
.color-block div {
  padding: 0 15px;
  line-height: 40px;
  font-size: 12px;
  margin-bottom: 3px;
}

.loader-demo{
  .loader{
    margin:30px 0;
  }
}

//Photoswipe Demo
#timline-gallery {
  margin: 15px 0;
  figure{
        padding: 2px;
    a{
      width: auto;
      height: auto;
    }
    figcaption {
      display: none;
    }
  }

}

.btn-equal-width{
  .btn{
    width: 40%;
  }
}
@media (max-width : 768px) {
  .btn-equal-width{
    .btn{
      width: 100%;
    }
  }
}
.tooltip-examples{
      padding: 0;
  li{
    display: inline-block;
    padding-right: 50px;
  }
}
