.context-menu {
	display: none;
	position: absolute;
	z-index: 10;
	width: 250px;
	padding: 10px 0;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 0;
	border-radius: 2px;
	-o-transition: all 150ms linear;
	transition: all 150ms linear;
	box-shadow: 0 5px 15px rgba(0,0,0,.15);
	transform: translateY(-2px);
	color: #a5b5cb;
		border-radius: 3px;
}

.context-menu--active {
	display: block;
}

.context-menu__items {
	list-style: none;
	margin: 0;
	padding: 0;
}

.context-menu__item {
	display: block;
	margin-bottom: 4px;
	position: relative;
}

.context-menu__link {
	display: block;
	padding: 10px 20px 10px 45px;
	font-size: 15px;
	border-left: solid 6px transparent;
	margin: 0 5px;
	color: #6f829c;
	font-weight: 600;
	text-decoration: none;
}

.context-menu__link:hover {
	-o-transition: all 150ms linear;
	transition: all 150ms linear;
	color: #fff;
	border-radius: 0 3px 3px 0;
}

.context-menu ul li.divider {
	margin: 5px 0;
	background-color: rgba(227, 235, 239, .5);
	height: 1px;
	overflow: hidden;
}

.context-menu ul li a i {
	font-size: em(25);
	padding: 4px 8px 0 10px;
	position: absolute;
	left: 10px;
	top: 3px;
}
