#content {
    .nav-pills > li {
        padding: 6px 0;
    }
    .nav-pills > li > a {
        border-radius: 3px;
        font-size: em(14);
    }
    .nav-pills.nav-center > li > a {
        text-align: center;
    }
    .nav-pills > li.active > a {
        box-shadow: 0 14px 26px -12px rgba(3,168,243,.42),0 4px 23px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(3,168,243,.2);
        color: #fff;
        will-change: box-shadow, transform;
        transition: box-shadow 0.2s $mdb-animation-curve-fast-out-linear-in, background-color 0.2s $mdb-animation-curve-default;
    }
    .nav-pills > li.active > a:focus,
    .nav-pills > li.active > a:hover {
        color: #fff;
        box-shadow: 0 14px 26px -12px rgba(3,168,243,.42),0 4px 23px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(3,168,243,.2);
    }
    .nav-pills > li > a:focus,
    .nav-pills > li > a:hover {
        background-color: #E3ECF7;
        color: #3e4c5e;
    }
    .nav-pills.block-pills {
        li a {
            line-height: 22px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            min-width: 100px;
            text-align: center;
            transition: all 0.3s;
            border-radius: 3px;
        }
        li i {
            display: block;
            font-size: 30px;
            padding: 15px 0;
        }
    }
}
