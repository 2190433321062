
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
    background: #EEF5F9;
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    /* Fix 401 */
}

.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-stacking .noUi-handle {
    /* This class is applied to the lower origin when
   its values is > 50%. */
    z-index: 10;
}

.noUi-state-tap .noUi-origin {
    -webkit-transition: left 0.3s,top 0.3s;
    transition: left 0.3s,top 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}
/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 4px;
}

.noUi-horizontal .noUi-handle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    left: -7px;
    top: -7px;
    background-color: #345DBB;
}
/* Styling;
 */
.noUi-background {
    background: #D6D7D9;
}

.noUi-connect {
    background: #345DBB;
    -webkit-transition: background 450ms;
    transition: background 450ms;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.noUi-origin {
    border-radius: 2px;
}

.noUi-target {
    border-radius: 2px;
}

.noUi-target.noUi-connect {}
/* Handles and cursors;
 */
.noUi-draggable {
    cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: n-resize;
}

.noUi-handle {
    cursor: default;
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    box-sizing: content-box !important;
}

.noUi-handle:active {
    border: 8px solid #345DBB;
    border: 8px solid rgba(53,93,187,0.38);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    left: -14px;
    top: -14px;
}
/* Disabled state;
 */
[disabled] .noUi-connect,
[disabled].noUi-connect {
    background: #B8B8B8;
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
    cursor: not-allowed;
}

.noUi-vertical {
    width: 4px;
    display: inline-block;
    height: 100%;
}

.slider {
    &.slider-info {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $brand-info;
        }

        .noUi-handle {
            border-color: $brand-info;
        }
    }

    &.slider-success {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $brand-success;
        }

        .noUi-handle {
            border-color: $brand-success;
        }
    }

    &.slider-warning {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $brand-warning;
        }

        .noUi-handle {
            border-color: $brand-warning;
        }
    }

    &.slider-danger {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $brand-danger;
        }

        .noUi-handle {
            border-color: $brand-danger;
        }
    }



    .noUi-handle {
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        left: -10px;
        top: -6px;
        cursor: pointer;
        border-radius: 100%;
        transition: all 0.2s ease-out;
        border: 1px solid;
        background: $white-color;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .noUi-handle {
        left: -6px;
    }
    .noUi-state-tap .noUi-origin {
        transition: left 0.3s, top 0.3s;
    }
    .noUi-handle.noUi-active {
        transform: scale3d(2, 2, 1);
    }
}
