.mail-app {
    #header_wrapper {
        padding: 15px 0;
        display: inline-block;
        .card-actions.left-top {
            left: 21px!important;
            top: 13px!important;
            z-index: 10;
        }

        .card-actions.icons > a,
        .card-actions.icons > li > a {
            width: 40px;
            height: 40px;
            padding-top: 11px;
        }

        #header {
            display: inline-block;

            h1 {
               padding-top:5px;
                display: inline-block;
            }
        }
    }

    #content {
        margin: 0 !important;
        padding: 0;
    }

    ul.note-labels {
        padding: 10px 0;
        margin-bottom: 0;
        list-style: none;
        position: relative;

        li {
            position: relative;
            display: block;
            padding: 10px 20px 10px 30px;
            font-size: 14px;

            a {
                position: relative;
                display: block;
                -webkit-transition: all 250ms linear;
                -khtml-transition: all 250ms linear;
                -moz-transition: all 250ms linear;
                -ms-transition: all 250ms linear;
                -o-transition: all 250ms linear;
                transition: all 250ms linear;

                &:hover {
                    color: #516073;
                }
            }

            &:hover {
                background: rgba(200,200,200,.2);
                cursor: pointer;
            }
        }

        i {
            width: 25px;
            font-size: 1.4em;
            margin-right: 30px;
            text-align: center;
            vertical-align: text-top;
            display: inline-block;
        }
    }

    #mail-wrapper {
        .block-header {
            padding: 10px 24px;
            position: relative;

            ul.card-actions.icons.right-top {
                right: 33px;
                top: 15px;
            }
        }

        .panel {
            .panel-heading {
                .panel-title {
                    position: relative;

                    > a {
                        padding: 10px;
                        min-height: 50px;

                        .avatar,
                        .content-teaser,
                        .info-wrapper,
                        .sender-wrapper {
                            float: left;
                            font-size: em(16);
                            color: #536b78;
                        }

                        .avatar {
                            margin: 0 10px;
                        }

                        .sender-wrapper {
                            width: 155px;
                            margin-top: 9px;
                        }

                        .sender {
                            text-overflow: ellipsis;
                            display: inline-block;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 100%;
                            font-weight: 400;
                        }

                        .info-wrapper {
                            box-sizing: border-box;
                            overflow: hidden;
                            display: inline-block;
                            margin-top: 9px;
                        }

                        .info {
                            text-overflow: ellipsis;
                            display: inline-block;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 100%;
                            font-family: $font-family-body;
                            font-weight: 200;
                            min-height: 25px;
                        }

                        &:hover {
                            color: #536b78;
                        }
                    }

                    ul.card-actions.right-top {
                        right: 10px;
                        top: 10px;

                        li {
                            margin-left: 0;
                        }

                        .dropdown-menu {
                            a {
                                font-size: 18px;

                                i {
                                    margin-right: 10px;
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }
            }

            .panel-footer {
                height: 60px;
                position: relative;
                background: #F7FBFE;

                ul.card-actions.icons.right-bottom {
                    top: -5px;
                }

                ul.card-actions.left-bottom {
                    left: 10px;
                    bottom: 14px;

                    > li a {
                        font-size: 13px;
                        margin: 7px 0;
                        padding: 10px;
                    }
                }

                ul.card-actions.right-bottom > li a {
                    margin: 20px 0;
                }
            }
        }

        .panel-group {
            margin: 0 auto 60px;

            .panel.active {
                box-shadow: 0 20px 25px rgba(0,0,0,.15);

                .content-teaser {
                    display: none;
                }
            }

            .panel .panel-heading a.collapsed:after,
            .panel .panel-heading a:after {
                content: '';
            }

            .panel-body {
                padding: 30px 110px;

                p {
                    font-size: em(16);
                }
            }
        }
    }

    .btn-fab-group {
        position: fixed;
        right: 30px;
        bottom: 30px;
    }

    #mail_compose_wrapper {
        width: 540px;
        height: 540px;
        position: fixed;
        bottom: -540px;
        right: 150px;
        background: #fff;
        @include border-radius(5px 5px 0 0);
        @include shadow-16dp();

        .tippy-top {
            background: #222F3C;
            height: 40px;

            ul.card-actions.icons {
                li {
                    a {
                        color: #fff;
                    }
                }
            }

            ul.card-actions.icons.left-top {
                left: 10px;
                top: 8px;
            }

            ul.card-actions.icons.right-top {
                right: 10px;
                top: 5px;
            }
            @include border-radius(5px 5px 0 0);
        }

        .compose-form .form-group .form-control {
            padding: 17px 0 25px 20px;
        }

        .compose-form .form-group textarea {
            background-image: none;
        }

        &.open {
            bottom: 0;
        }

        &.min {
            bottom: -500px;
            width: 275px;
        }

        &.expand {
            bottom: 10%;
            top: 10%;
            left: 10%;
            right: 10%;
            width: 80%;
            height: 80%;
            z-index: 199;
        }
    }

  
}
@media (max-width: 1380px) {
    .mail-app #mail-wrapper {
        padding: 0;
    }
}
@media (max-width: 1280px) {
    .mail-app {
        .container {
            margin: 0 !important;
        }

        #header_wrapper form {
            width: 65%;
            display: inline-block;
            margin: 0 30px 0 45px;
        }

        #mail-wrapper {
            .panel-group {
                margin: 0 auto;
                width: 100%;
                padding: 0;

                .block-header {
                    padding: 10px 0;
                }
            }

            .panel-group.expansion .panel {
                margin: 0;
            }

            .panel {
                .panel-heading {
                    .panel-title {
                        position: relative;

                        > a {
                            padding: 10px;
                            min-height: 50px;

                            .avatar,
                            .content-teaser,
                            .info-wrapper,
                            .sender-wrapper {
                                float: left;
                                font-size: em(16);
                                color: #536b78;
                            }

                            .avatar {
                                margin: 0 10px;
                            }

                            .sender-wrapper {
                                width: 155px;
                            }

                            .sender {
                                text-overflow: ellipsis;
                                display: inline-block;
                                white-space: nowrap;
                                overflow: hidden;
                                width: 100%;
                            }

                            .info-wrapper {
                                box-sizing: border-box;
                                overflow: hidden;
                                display: inline-block;
                                margin-top: 9px;
                                width: 70%;
                            }

                            .info {
                                text-overflow: ellipsis;
                                display: inline-block;
                                white-space: nowrap;
                                overflow: hidden;
                                width: 100%;
                            }

                            &:hover {
                                color: #536b78;
                            }
                        }

                        ul.card-actions.right-top {
                            right: 10px;
                            top: 10px;

                            li {
                                margin-left: 0;
                            }

                            .dropdown-menu {
                                a {
                                    font-size: 18px;

                                    i {
                                        margin-right: 10px;
                                        font-size: 22px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: $screen-lg) {
    .mail-app {
        #header_wrapper form {
            display: none;
        }

        #mail-wrapper {
            margin: 0;
            padding: 0;

            .panel-group.expansion .panel {
                margin: 0;

                ul.card-actions.right-top {
                    right: 33px;
                    top: 10px;
                }

                .panel-body {
                    padding: 20px;
                }
            }
        }

        #mail-wrapper .panel .panel-heading .panel-title > a {
            padding: 10px;
        }

        #mail-wrapper {
            .panel {
                .panel-heading {
                    .panel-title {
                        > a {
                            .sender-wrapper {
                                margin-top: 9px;
                                padding: 0;
                            }

                            .info-wrapper {
                                margin-top: 9px;
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: $screen-sm) {
    .mail-app {
        #header_wrapper form {
            display: none;
        }



        #mail-wrapper {
            padding: 20px;
            margin: 0;

            .panel-group.expansion .panel {
                margin: 0;

                ul.card-actions.right-top {
                    right: 5px;
                    top: 10px;
                }

                .panel-body {
                    padding: 20px;
                }
            }

            .panel .panel-heading .panel-title > a .sender-wrapper {
                width: 120px;
            }
        }

        #mail-wrapper .panel .panel-heading .panel-title > a {
            padding: 10px;
        }

        #mail-wrapper {
            .panel {
                .panel-heading {
                    .panel-title {
                        > a {
                            .sender-wrapper {
                                margin-top: 0;
                                padding: 0;
                            }

                            .info-wrapper {
                                margin-top: 0;
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }

        #mail_compose_wrapper {
            width: 100%;
            height: 100%;
            position: fixed;
            right: 0;
            left: 0;
            z-index: 99;
            top: 0;
            bottom: 0;
            background: #fff;
            -webkit-border-radius: 5px 5px 0 0;
            border-radius: 0;
            background-clip: padding-box;
            box-shadow: none;
            display: none;

            .tippy-top {
                border-radius: 0;

                ul.card-actions.icons.right-top {
                    right: 10px;
                    top: 0;
                }
            }

            .mdi-arrow-expand,
            .zmdi-window-minimize {
                display: none;
            }

            &.open {
                display: block;
            }
        }
    }
}
@media (max-width:535px) {
    .mail-app #mail-wrapper .panel .panel-heading .panel-title > a {
        padding: 10px;
        height: 60px;
    }

    .mail-app #mail-wrapper .block-header ul.card-actions.icons.right-top {
        right: 6px;
        top: 15px;
    }
}
