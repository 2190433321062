.well {
    min-height: 20px;
    padding: 10px;
    margin: 10px 0;
    background-color:#EEF5F9;
    border: solid thin #E3ECF7;
    border-radius: 3px;
    box-shadow: none;
    h4,p{
      padding:5px 0!important;
      margin: 0!important;
    }
}
