.togglebutton {
  vertical-align: middle;
  &, label, input, .toggle {
    user-select: none;
  }
  label {
    cursor: pointer;
    font-weight: 600;
    @include mdb-label-color-toggle-focus();
    input[type=checkbox] {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .toggle {
      text-align: left;
      margin-left: 5px;
    }
    .toggle,
    input[type=checkbox][disabled] + .toggle {
      content: "";
      display: inline-block;
      width: 30px;
      height: 15px;
      background-color: #899cb2;
      border-radius: 15px;
      margin-right: 15px;
      transition: background 0.3s ease;
      vertical-align: middle;
    }
    .toggle:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
      left: -5px;
      top: -3px;
      border: 1px solid #788da5;
      transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    }
    input[type=checkbox] {
      &[disabled] {
        & + .toggle:after,
        &:checked + .toggle:after {
          background-color: #899cb2;
        }
      }
      & + .toggle:active:after,
      &[disabled] + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
      }
      &:checked + .toggle:after {
        left: 15px;
      }
    }
    input.toggle-info[type=checkbox]:checked {
      + .toggle {
        background-color: rgba($brand-info, (70/100));
      }

      + .toggle:after {
        border-color: $brand-info;
      }

      + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($brand-info, (10/100));
      }
    }
    input.toggle-warning[type=checkbox]:checked {
      + .toggle {
        background-color: rgba($brand-warning, (70/100));
      }

      + .toggle:after {
        border-color: $brand-warning;
      }

      + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($brand-warning, (10/100));
      }
    }
    input.toggle-success[type=checkbox]:checked {
      + .toggle {
        background-color: rgba($brand-success, (70/100));
      }

      + .toggle:after {
        border-color: $brand-success;
      }

      + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($brand-success, (10/100));
      }
    }
    input.toggle-default[type=checkbox]:checked {
      + .toggle {
        background-color: rgba($brand-default, (70/100));
      }

      + .toggle:after {
        border-color: $brand-default;
      }

      + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($brand-default, (10/100));
      }
    }
  }
}







.checkbox-pin {
  position: relative;
  display: inline-block;
}
.checkbox-pin label {
  width: 43px;
  height: 18px;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.checkbox-pin label:after, .checkbox-pin label:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  right: -3px;
  top: -5px;
  background: #fff;
    box-shadow: 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.14);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.checkbox-pin label:before {
  font-family: "Material Design Icons";
  content: "\F403";
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;

  z-index: 1;
  text-align: center;
  overflow: hidden;
  -webkit-animation: 0.5s pin-off forwards;
  animation: 0.5s pin-off forwards;
}

.checkbox-pin input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
.checkbox-pin input:hover + label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}

.checkbox-pin input:checked + label:after, .checkbox-pin input:checked + label:before {
  color: #fff;
  right: 22px;
}
.checkbox-pin input:checked + label:before {
  -webkit-animation: 0.5s pin-on forwards;
  animation: 0.5s pin-on forwards;
}
@-webkit-keyframes pin-on {
  80% {
    line-height: 150px;
  }
  85% {
    line-height: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    line-height: 30px;
    opacity: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
@keyframes pin-on {
  80% {
    line-height: 150px;
  }
  85% {
    line-height: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    line-height: 28px;
    opacity: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
@-webkit-keyframes pin-off {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  80% {
    line-height: 150px;
  }
  85% {
    line-height: 1px;
    opacity: 0;
  }
  100% {
    line-height: 28px;
    opacity: 1;
  }
}
@keyframes pin-off {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  80% {
    line-height: 150px;
  }
  85% {
    line-height: 1px;
    opacity: 0;
  }
  100% {
    line-height: 28px;
    opacity: 1;
  }
}
