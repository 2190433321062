.notes-app {
  #header_wrapper {
    padding: 17px 0;
    display: inline-block;
    
    #header {
      display: inline-block;
      
      h1 {
        padding-top: 5px;
        display: inline-block;
      }
    }
  }
  
  #content {
    margin: 0 !important;
    padding: 0;
    
    #leftnav {
      .card {
        margin: 0;
        
        .card-heading {
          padding: 0 0 0 25px;
          
          .card-title {
            font-size: em(15);
            font-weight: 400;
            padding: 0;
            margin: 0;
          }
        }
        
        .card-body {
          padding: 0;
        }
      }
      
      ul.note-labels {
        padding: 10px 0;
        margin-bottom: 0;
        list-style: none;
        position: relative;
        
        li {
          position: relative;
          display: block;
          padding: 10px 20px 10px 25px;
          font-size: 14px;
          
          a {
            position: relative;
            display: block;
            -webkit-transition: all 250ms linear;
            -khtml-transition: all 250ms linear;
            -moz-transition: all 250ms linear;
            -ms-transition: all 250ms linear;
            -o-transition: all 250ms linear;
            transition: all 250ms linear;
            
            &:hover {
              color: #516073;
            }
          }
          
          &:hover {
            background: rgba(200,200,200,.2);
            cursor: pointer;
          }
        }
        
        i {
          width: 25px;
          font-size: 1.4em;
          margin-right: 30px;
          text-align: center;
          vertical-align: text-top;
          display: inline-block;
        }
      }
    }
    
    .content-body {
      .card-add-note {
        max-width: 575px;
        margin: 30px auto;
        
        .form-group {
          margin: 35px 0 0;
        }
        
        &.card-add-note {
          cursor: pointer;
        }
      }
      
      .card-add-note {
        .notesTempImgWrapper {
          display: none;
        }
        
        #note_form {
          height: 0;
          overflow-y: hidden;
          @include transition(all, 100ms, ease-in-out);
          
          .card-actions {
            label {
              font-size: 13px;
            }
          }
          
          .card-actions li.dropdown .dropdown-menu {
            padding: 10px;
            width: 200px;
          }
        }
      }
      
      .card-add-note.open {
        .card-heading {
          display: none;
        }
        
        #note_form {
          height: auto;
          overflow: visible;
        }
      }
      
      .card-note {
        .card-actions.icons > a.open:before,
        .card-actions.icons > a:hover:before,
        .card-actions.icons > li > a.open:before,
        .card-actions.icons > li > a:hover:before {
          opacity: 0!important;
        }
        position: relative;
        border: solid 3px transparent;
        margin-bottom: 10px;
        @include transition(all, .3s, ease-in-out);
        
        .card-heading {
          padding: 10px;
          position: initial;
          
          .card-title {
            font-size: 15px;
          }
        }
        
        .card-actions.right-top {
          visibility: hidden;
          right: -10px;
          top: -13px;
          
          a {
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background: #384555;
            padding: 0;
            margin: 0;
            border: solid 3px #fff;
            
            i {
              font-size: 30px;
              color: #fff;
              margin: -4px;
            }
          }
        }
        
        &.selected {
          border: solid 3px #384555;
          
          .card-actions.right-top {
            visibility: visible;
            
            a {
              background: #fff;
              
              i {
                color: #384555;
              }
            }
          }
        }
        
        .card-body {
          padding: 10px;
          
          .checklist {
            margin: 0;
            padding: 0;
            list-style: none;
          }
        }
        
        .card-footer {
          padding: 10px;
          min-height: 30px;
          
          .card-actions.icons.left-bottom {
            padding: 0 10px;
            top: -4px;
            left: 0;
            display: none;
          }
        }
      }
      
      .card-note:hover {
        @include transform(translateY(-2px));
        box-shadow: 0 20px 25px rgba(0,0,0,.15);
        z-index: 99;
        
        .card-actions.right-top {
          visibility: visible;
          
          li {
            cursor: pointer;
          }
        }
        
        .card-footer {
          .card-actions.icons.left-bottom {
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .notes-app {
    #masonry {
      margin-top: 90px;
    }
    
    .card.card-add-note {
      margin: 0 auto;
    }
  }
}

.swatches {
  padding: 10px;
}

.swatches > li > input[type=radio] {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.swatches > li {
  width: 30px;
  height: 30px;
  margin: 0 0 3px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border: solid 2px;
}

.swatches li {
  display: inline-block!important;
  border-radius: 50%;
}

.swatches > li.color-tag__default {
  border: 1px solid #eee;
  background-color: #fff!important;
}

.swatches > li.color-tag__default > i:before {
  color: #333;
}

.swatches > li,
.swatches > li > i {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.swatches > li > input[type=radio] {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.swatches > li > input[type=radio]:checked+i {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.swatches > li:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.swatches > li > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 4px 0 0 3px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.swatches > li > i:before {
  content: '\f26b';
  font-family: Material-Design-Iconic-Font;
  color: rgba(#688696,.6);
  font-size: 20px;
  z-index: 1;
}

.swatches > li > i.dark:before {
  content: '\f26b';
  font-family: Material-Design-Iconic-Font;
  color: rgba(#fff,1);
  font-size: 20px;
  z-index: 1;
}
