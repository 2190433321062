.tabpanel {
  //@include box-shadow(0, 0 ,5px, rgba(0,0,0,.3));
}

.nav-tabs {
  border: none;
  background: #fff;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  padding-top: 0;
  box-shadow: inset 0 -2px 0 0 #e6edf1;
  
  li a {
    border: none;
    border-radius: 0;
    font-size: em(13);
    padding: 15px;
    
    &:hover {
      background-color: transparent;
    }
  }
  
  &.darken {
    box-shadow: inset 0 -2px 0 0 darken(#eef5f9,6%);
  }
}

.nav-tabs.nav-justified > li > a {
  margin-bottom: 0;
}

.nav-tabs li.active > a {
  color: #3e4c5e;
}

.nav-tabs li a:after {
  content: "";
  left: 0;
  height: 2px;
  position: absolute;
  width: 100%;
  bottom: 0;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.nav-tabs li.active a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.nav-tabs.nav-tabs-right {
  text-align: right;
}

.nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
  text-align: center;
  float: none;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #516073;
  cursor: default;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: transparent;
}

.nav-tabs.alt-tabs {
  background: transparent;
  
  li a:after {
    bottom: 1px;
  }
  
  li > a {
    color: rgba(255,255,255,.7);
    font-size: em(16);
  }
  
  li.active > a,
  li.active > a:focus,
  li.active > a:hover {
    color: #fff;
    cursor: default;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: transparent;
  }
}

.nav-tabs.nav-tabs-right {
  text-align: right;
  
  li {
    display: inline-block;
    float: none;
  }
}
//Tabs justified

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > li > a {
  font-size: 14px;
  padding: 21px 0;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > li > a {
  border: none;
}
//Tabs on card

.card.card-nav-tabs {
  margin-top: 45px;
  display: inline-block;
  position: relative;
  
  .nav-tabs-navigation {
    .nav-tabs-wrapper {
      padding: 0 15px;
    }
    
    .nav-tabs li.active a:after {
      background: none;
      height: 0;
    }
    
    .nav-tabs li a:after {
      height: 0;
      bottom: 0;
    }
  }
  
  .card-heading {
    margin: -30px 15px 15px;
    border-radius: 3px;
    padding: 15px 0;
    
    &.heading-default {
      background: $brand-default;
      @include button-shadow-color($brand-default);
      color: #fff;
    }
    
    &.heading-success {
      background: $brand-success;
      @include button-shadow-color($brand-success);
      color: #fff;
    }
    
    &.heading-info {
      background: $brand-info;
      @include button-shadow-color($brand-info);
      color: #fff;
    }
    
    &.heading-danger {
      background: $brand-danger;
      @include button-shadow-color($brand-danger);
      color: #fff;
    }
    
    .nav.nav-tabs {
      background: transparent;
      box-shadow: none;
    }
    
    .nav-tabs > li > a {
      padding: 15px;
      color: rgba(255,255,255,.7);
      max-height: 48px;
    }
    
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:focus,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li:hover > a {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      transition: all 250ms;
      border-radius: 3px;
    }
  }
}

.nav-tabs.white li.active > a {
  color: #fff!important;
}

.nav-tabs.white li > a {
  color: rgba(255,255,255,.7)!important;
}
//syntax-highlighter tabs

#content {
  .tabpanel.syntax-highlighter {
    .nav.nav-tabs {
      background: #1C2732;
      padding: 0;
    }
    
    .nav > li > a {
      padding: 15px !important;
    }
    
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:focus,
    .nav-tabs > li.active > a:hover {
      color: #fff;
      cursor: default;
      background-color: transparent;
      border: 1px solid transparent;
      border-bottom-color: transparent;
    }
    
    .nav > li > a:focus,
    .nav > li > a:hover {
      background-color: transparent!important;
      color: #ffffff;
      outline: none;
    }
    
    pre[class*=language-] {
      padding: 1em;
      margin: 0;
      overflow: auto;
      border-radius: 0;
      border: 0;
    }
  }
}


  #content_wrapper{
    .nav-tabs.scrollable{
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      margin: 0 auto;
      white-space: nowrap;
      display: flex;
    }
  }
