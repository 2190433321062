
.list-group {
  .list-group-item:first-child,
  .list-group-item:last-child {
    @include border-radius(0)
  }

  .list-group-item{
    border-left: none;
    border-right: none;
    font-size: em(13);
    border: 1px solid #E5EFF5;
    border: 0;
    margin: 0;
    padding: 12px 20px;
    color:#607188;
    &.active,
    &:active,
    &:focus,
    &:hover {
      background-color: #EEF5F9;
      cursor: pointer;
      color:#607188;
    }

    .list-group-item-body {
      zoom: 1;
      display: block;
      width: auto;
      color:#607188;
      .list-group-item-heading {
        font-weight: 600;
        margin: 0;
      }

      .list-group-item-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .list-group-item-text{
      color:#607188;
      &.active,
      &:active,
      &:focus,
      &:hover {
        color:#607188;
      }

    }
  }
  .list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text{
    color:#607188;
  }
  .list-group-item.active,
  .list-group-item.active:focus,
  .list-group-item.active:hover {
    background-color: #EEF5F9;
    color:#607188;
  }
}

.list-group {
  .list-group-item {
    background-color: transparent;
    overflow: hidden;
    border: 0;
    border-radius: 0;

    .row-action-primary {
      display: inline-block;
      padding-right: 16px;
      vertical-align: bottom;
    }

    .row-content {
      display: inline-block;
      width: -webkit-calc(100% - 92px);
      width: calc(100% - 92px);
      min-height: 30px;

      .least-content {
        position: absolute;
        right: 16px;
        top: 30px;
        color: #bcced6;
        font-size: 1.8em;

        .checkbox,
        .radio {
          margin: 0;
        }
      }

      .list-group-item-heading {
        color: rgba(0, 0, 0, 0.77);
        font-size: 20px;
        margin: 0;
      }

      .list-group-item-text {
        margin-bottom: 0;
        line-height: 1.8em;
      }
    }
  }

  .circle {
    border-radius: 100%;
    text-align: center;
    line-height: 56px;
    width: 56px;
    width: 56px;
    font-size: 20px;
    color: white;
  }
}
