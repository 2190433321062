.timeline-page{
  .pika-single{
    // left: 50%!important;
    // top: 220px!important;
    border-color:#D7E4ED;
  }
  .timeline-container {
    position: relative;
    padding: 20px 0;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    .meeting-timeline-block {
      position: relative;
      margin: 0;
    }
    .meeting-timeline-block:after {
      content: "";
      display: table;
      clear: both;
    }
    .meeting-timeline-block:first-child {
      margin-top: 0;
    }
    .meeting-timeline-block:last-child {
      margin-bottom: 0;
    }

    .card.card-timeline .meeting-time, .meeting-timeline-icon,  .meeting-timeline-icon i {
      display: none;
    }
    .card.card-date{
      width: 193px;
      height: 45px;
      top: -24px;
      z-index: 99;
      border-radius: 30px;
      position: relative;
      margin:30px auto 0 auto;
      .form-group{
        margin: 3px 0 0 9px;
        padding: 0;
        .input-group{
          position: relative;
          display: table;
          border-collapse: separate;
          /* text-align: center; */
          width: 100%;
          padding-top: 4px;
        }
        .input-group .input-group-addon{
          border: 0;
          background: 0 0;
          color: #9daec4;
        }
        .form-control.datepicker{
          margin: 0;
          background-image: none;
          padding-left: 35px;
        }
      }
      .input-group .input-group-addon{
        color: #9daec4;
        font-size: 21px;
        padding: 9px 11px 9px 9px;
        position: absolute;
      }
      .form-control.datepicker {
        margin: 0;
        background-image: none;
        color: #9daec4;

      }
    }
  }
  #meeting-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
  }
}

.card.card-timeline {
  position: relative;
  background: white;
  border-radius: 0.25em;
  padding: 0;
  margin-bottom: 10px;
  .card-heading{
    small{
      display: inline;
    }
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  h2 {
    color: #303e49;
  }
  p, .meeting-time {
    font-size: em(14);
    margin: 1em 0;
    line-height: 1.6;
    display: inline-block;
  }
  .list-group-item{
    img{
      margin-left:-13px;
      border:solid 2px #fff;
      @include box-shadow(0 5px 10px rgba(0,0,0,.10));
      &:hover{
        @include transform(scale(1.3));
        @include shadow-z-1-hover();

      }
    }
  }
}

.card-timeline .meeting-time {
  display: none;
}
.card-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
  border: none;
  border-right:  none;
}

@media only screen and (min-width: 1170px) {
  .timeline-page{
    #meeting-timeline {
      width:90%;
      margin-top: 3em;
      margin-bottom: 3em;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 4px;
        background: -moz-linear-gradient(top,  rgba(215,228,237,1) 89%, rgba(217,230,238,1) 90%, rgba(238,245,249,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(215,228,237,1) 89%,rgba(217,230,238,1) 90%,rgba(238,245,249,0) 100%);
        background: linear-gradient(to bottom,  rgba(215,228,237,1) 89%,rgba(217,230,238,1) 90%,rgba(238,245,249,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7e4ed', endColorstr='#00eef5f9',GradientType=0 );
        left: 50%;
        margin-left: -2px;
      }
      .card.card-date{
        margin: 0 auto;
      }
      .card.card-timeline{
        margin-left: 0;
        padding:0;
        width: 45%;
        margin-bottom: 30px;
        &::before {
          top: 24px;
          left: 100%;
          border-color: transparent;
          border-left-color: white;
        }
        .card-heading{
          small{
            display: none;
          }
        }
        .meeting-time {
          position: absolute;
          width: 100%;
          left: 122%;
          top: -8px;
          display: block;
        }
      }
      .card-timeline::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 100%;
        height: 0;
        width: 0;
        border: 7px solid transparent;
        border-right: 7px solid white;
      }
      .meeting-timeline-block {
        margin: 0;
      }
      .meeting-timeline-block:first-child {
        margin-top: 0;
      }
      .meeting-timeline-block:last-child {
        margin-bottom: 0;
      }
      .meeting-timeline-block:nth-child(even) .card-timeline {
        float: right;
      }
      .meeting-timeline-block:nth-child(even) .card-timeline::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
      }

      .meeting-timeline-block:nth-child(even) .card-timeline .meeting-time {
        left: auto;
        right: 122%;
        text-align: right;
      }
      .meeting-timeline-icon {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -19px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $mw-blue;
        box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
        display: block;
        i {
          display: block;
          width: 24px;
          height: 24px;
          position: relative;
          left: 50%;
          top: 50%;
          margin-left: -8px;
          margin-top: -11px;
          color:#fff;
          font-size: em(21);
          display: block;
        }
      }
      .meeting-time {
        float: left;
        opacity: .7;
        display: block;
      }
    }

  }
  
}


.sidebar-timeline {
  border-left: 2px solid #4285F4;
  position: relative;
  margin:20px;
}
.sidebar-timeline .time-item:after {
  background-color: #ffffff;
  border-color: #4285F4;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 10px;
  left: 0;
  margin-left: -6px;
  position: absolute;
  top: 5px;
  width: 10px;
}
.time-item {
  border-color: #4285F4;
  padding-bottom: 10px;
  position: relative;
}
.time-item:before {
  content: " ";
  display: table;
}
.time-item:after {
  background-color: #ffffff;
  border-color: #039cfd;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.time-item-item:after {
  content: " ";
  display: table;
}
.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}
.item-info p {
  font-size: 13px;
}
