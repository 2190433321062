@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  1% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.scale {
  animation: scale 200ms cubic-bezier(0, 0, 0.2, 1);

}
.tooltip.fade {
  transition: none;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-inner {
    background-color: darken(#9daec4, 10%);
    color: #fff;
}
.tooltip.top .tooltip-arrow {
    border-top-color: darken(#9daec4, 10%);
}
.tooltip.right .tooltip-arrow {
    border-right-color: darken(#9daec4, 10%);
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: darken(#9daec4, 10%);
}
.tooltip.left .tooltip-arrow {
    border-left-color: darken(#9daec4, 10%);
}


.alt-tooltip + .tooltip > .tooltip-inner {
    background-color: rgba(255,255,255,.5);
      color:rgba(78,93,111,.8);
}
.alt-tooltip + .tooltip.top .tooltip-arrow {
    border-top-color: rgba(255,255,255,.5);

}
.alt-tooltip + .tooltip.right .tooltip-arrow {
    border-right-color: rgba(255,255,255,.5);

}
.alt-tooltip + .tooltip.bottom .tooltip-arrow {
    border-bottom-color:rgba(255,255,255,.5);

}
.alt-tooltip + .tooltip.left .tooltip-arrow {
    border-left-color:rgba(255,255,255,.5);

}
